export interface SurveySelectOption<T extends string | number> {
    label: string;
    value: T;
}

export const Q1_AnalyzeRisk_Values: SurveySelectOption<number>[] = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
    { label: "2020", value: 2020 },
    { label: "2019", value: 2019 },
    { label: "2018", value: 2018 },
    { label: "2017", value: 2017 },
    { label: "2016 eða fyrr", value: 2016 },
    { label: "Ekki lokið", value: 1 },
    { label: "Lokið, en ekki viss um hvaða ár", value: 2 },
    { label: "Ekki viss", value: 3 },
];

export const QBasic_AnalyzeRisk_Values: SurveySelectOption<number>[] = [
    { label: "Já", value: 0 },
    { label: "Að hluta", value: 1 },
    { label: "Nei", value: 2 },
    { label: "Ekki viss", value: 3 },
];

export const Q5_AnalyzeRisk_Values: SurveySelectOption<number>[] = [
    { label: "Já", value: 0 },
    { label: "Nei", value: 1 },
    { label: "Ekki viss", value: 2 },
];

export const Q7_AnalyzeRisk_Values: SurveySelectOption<number>[] = [
    { label: "Skipulagsáætlun sveitarfélagsins", value: 0 },
    { label: "Aðalskipulag", value: 1 },
    { label: "Deiliskipulag sveitarfélagsins (áætlun um öryggi almennings og viðbúnað vegna neyðar)", value: 2 },
    { label: "Fjármálaáætlun sveitarfélagsins", value: 3 },
    { label: "Brunavarnaáætlun sveitarfélagsins", value: 4 },
    { label: "Aðrar áætlanir / önnur stjórnunarskjöl", value: 5 },
    { label: "Ekkert af þessu", value: 6 },
    { label: "Ekki viss", value: 7 },
];

export const Q9_AnalyzeRisk_Values: SurveySelectOption<number>[] = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
    { label: "2020", value: 2020 },
    { label: "2019", value: 2019 },
    { label: "2018 eða fyrr", value: 2018 },
    { label: "Ekki viss", value: 1 },
];

export const Q12_AnalyzeRisk_Values: SurveySelectOption<number>[] = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
    { label: "2020", value: 0 },
    { label: "2019", value: 1 },
    { label: "2018 eða fyrr", value: 2 },
    { label: "Já, en ekki viss hvaða ár", value: 3 },
    { label: "Nei, engin slík æfing hefur átt sér stað", value: 4 },
    { label: "Ekki viss", value: 5 },
];

export const Q16_Collaboration: SurveySelectOption<number>[] = [
    { label: "Önnur sveitarfélög", value: 0 },
    { label: "Aðrir opinberir aðilar", value: 1 },
    { label: "Lögreglustjóri", value: 2 },
    { label: "Einkaaðilar", value: 3 },
    { label: "Almannavarnir", value: 4 },
    { label: "Frjáls félagasamtök", value: 5 },
    { label: "Engir af framantöldum", value: 6 },
    { label: "Ekki viss", value: 7 },
];

export const themeOverview_Q3_SystemUsed: SurveySelectOption<number>[] = [
    { label: "Já", value: 0 },
    { label: "Nei", value: 1 },
    { label: "Ekki viss", value: 2 },
    { label: "Annað (skrá hér að neðan)", value: 3 },
];

export const themeFuel_Q6_ProblemsOccured: SurveySelectOption<number>[] = [
    { label: "Aldrei", value: 0 },
    { label: "Einu sinni til þrisvar sinnum", value: 1 },
    { label: "Oftar en þrisvar sinnum", value: 2 },
    { label: "Ekki viss", value: 3 },
];

export const themeOwnPreparation_Q1_WeAreAllAlmannavarnir: SurveySelectOption<number>[] = [
    { label: "Já, og starfsemi í tengslum við átakið hefur verið framkvæmd í sveitarfélaginu", value: 0 },
    { label: "Já, en ekkert hefur verið gert í sveitarfélaginu til að taka þátt í átakinu", value: 1 },
    { label: "Nei, ekki kunnugt um átakið", value: 2 },
];

export const themeOwnPreparation_Q2_WhatOperationValues: SurveySelectOption<number>[] = [
    { label: "Upplýsingabás", value: 0 },
    { label: "Uppsetning sýningar", value: 1 },
    { label: "Upplýsingamiðlun í eigin starfsemi", value: 2 },
    { label: "Birting fréttar/greinar á heimasíðu sveitarfélagsins", value: 3 },
    { label: "Samfélagsmiðlafærslur og dreifing þeirra", value: 4 },
    { label: "Kynningar", value: 5 },
    { label: "Birting efnis um eigin viðbúnað í staðbundnum miðlum", value: 6 },
    { label: "Fræðsla í skólum", value: 7 },
    { label: "Aðrar ráðstafanir (skrá hér í næsta reit)", value: 8 },
    { label: "Ekki viss", value: 9 },
];

export const TQ1_Values: SurveySelectOption<number>[] = [
    { label: "Fundir", value: 0 },
    { label: "Fjar- / símafundir", value: 1 },
    { label: "Tölvupóstur", value: 2 },
    { label: "Kort", value: 3 },
    { label: "Ljósmynd / kvikmynd", value: 4 },
    { label: "Annað (skrá hér að neðan)", value: 5 },
    { label: "Ekki viss", value: 6 },
];

export const TQ2_Values: SurveySelectOption<number>[] = [
    { label: "Kortalausnir sveitarfélagsins", value: 0 },
    { label: "Aðrar kortalausnir (skrá hér að neðan)", value: 1 },
    { label: "Sveitarfélagið notar ekki kort við atvik", value: 2 },
    { label: "Ekki viss", value: 3 },
];

export const Risk_uncertainty_Values: SurveySelectOption<number>[] = [
    { label: "Lítil", value: 0 },
    { label: "Meðal", value: 1 },
    { label: "Mikil", value: 2 },
];

export const Risk_likelyhood_Values: SurveySelectOption<number>[] = [
    { label: "A - Mjög litlar (Sjaldnar en 1 sinni á 1000 árum)", value: 0 },
    { label: "B - Litlar (1 sinni á 100 til 1000 árum)", value: 1 },
    { label: "C - Meðal (1 sinni á 50 til 100 árum)", value: 2 },
    { label: "D - Miklar (1 sinni á 10 til 50 árum)", value: 3 },
    { label: "E - Mjög miklar (Oftar en 1 sinni á 10 árum)", value: 4 },
    { label: "Ekki metið", value: 5 },
];

export const Risk_LifeAndHealth_NumDeceased_Values: SurveySelectOption<number>[] = [
    { label: "Enginn", value: 1 },
    { label: "1–2", value: 2 },
    { label: "3–5", value: 3 },
    { label: "6–10", value: 4 },
    { label: "> 10", value: 5 },
    { label: "Á ekki við", value: 6 },
];

export const Risk_LifeAndHealth_NumInjured_Values: SurveySelectOption<number>[] = [
    { label: "1–2", value: 1 },
    { label: "3–5", value: 2 },
    { label: "6–20", value: 3 },
    { label: "20-100", value: 4 },
    { label: "> 100", value: 5 },
    { label: "Á ekki við", value: 6 },
];

export const Risk_Effects_Duration_Values: SurveySelectOption<number>[] = [
    { label: "<1 dagur", value: 1 },
    { label: "1-2 dagar", value: 2 },
    { label: "2-7 dagar", value: 3 },
    { label: ">7 dagar", value: 4 },
    { label: "Á ekki við", value: 5 },
];

export const Risk_Stability_NumPeople_Values: SurveySelectOption<number>[] = [
    { label: "< 50 einstaklingar", value: 1 },
    { label: "50-200 einstaklingar", value: 2 },
    { label: "200-1000 einstaklingar", value: 3 },
    { label: "> 1000 einstaklingar", value: 4 },
    { label: "Á ekki við", value: 5 },
];

export const Risk_natureAndEnvironment_Duration_Values: SurveySelectOption<number>[] = [
    { label: "3-10 ár", value: 1 },
    { label: "> 10 ár", value: 2 },
    { label: "Á ekki við", value: 3 },
];

export const Risk_natureAndEnvironment_Area_Values: SurveySelectOption<number>[] = [
    { label: "< 3 km2/km", value: 1 },
    { label: "3-30 km2/km", value: 2 },
    { label: "30-300 km2/km", value: 3 },
    { label: "> 300 km2/km", value: 4 },
    { label: "Á ekki við", value: 5 },
];

export const Risk_natureAndEnvironment_Scope_Values: SurveySelectOption<number>[] = [
    { label: "Takmörkuð eyðilegging", value: 1 },
    { label: "Mikil eyðilegging", value: 2 },
    { label: "Á ekki við", value: 3 },
];

export const Risk_natureAndEnvironment_Protection_Values: SurveySelectOption<number>[] = [
    { label: "Menningarminjar sem eru verðugar verndar", value: 1 },
    { label: "Menningarlegt umhverfi sem er verðugt verndar", value: 2 },
    { label: "Friðaðar menningarminjar", value: 3 },
    { label: "Friðað menningarlegt umhverfi", value: 4 },
    { label: "Á ekki við", value: 5 },
];

export const Risk_physicalValuables_Values: SurveySelectOption<number>[] = [
    { label: "< 1.500 mill. krónur", value: 1 },
    { label: "1.500–8000 mill. krónur", value: 2 },
    { label: "8 – 30 mrd. krónur", value: 3 },
    { label: "30 – 80 mrd. krónur", value: 4 },
    { label: "> 80 mrd. krónur", value: 5 },
    { label: "Á ekki við", value: 6 },
];

export const OrganizationTypes: SurveySelectOption<number>[] = [
    { label: "Sveitarfélag", value: 1 },
    { label: "Ríkisstofnun", value: 2 },
    { label: "Fyrirtæki", value: 3 },
    { label: "Ráðuneyti", value: 4 },
];

export const UserJobRoles: SurveySelectOption<number>[] = [
    { label: "Stjórnandi Almannavarna", value: 1 },
    { label: "Starfsmaður Almannavarna", value: 2 },
    { label: "Starfsmaður stofnunar", value: 3 },
    { label: "Stjórnandi stofnunar", value: 4 },
];

export const CommunityProjects: { id: number; label: string; shortText: string }[] = [
    { id: 1, label: "Dreifing matvæla og lyfja", shortText: "Matvæli & lyf" },
    { id: 2, label: "Uppfylla þarfir um húsaskjól og hita", shortText: "Húsaskjól" },
    { id: 3, label: "Dreifing orku", shortText: "Orka" },
    { id: 4, label: "Dreifing eldsneytis", shortText: "Eldsneyti" },
    { id: 5, label: "Aðgengi að samskiptum (sími og net)", shortText: "Samskipti" },
    { id: 6, label: "Vatnveita og frárennsli", shortText: "Vatnsveita" },
    { id: 7, label: "Samgöngur (fólk og flutningar)", shortText: "Samgöngur" },
    { id: 8, label: "Þjónusta við viðkvæma hópa", shortText: "Viðkvæmir hópar" },
    { id: 9, label: "Heilbrigðis- og félagsþjónusta", shortText: "Heilbr- & fél. þj." },
    { id: 10, label: "Brunavarnir og aðrir viðbragðsaðilar", shortText: "Brunavarnir" },
    { id: 11, label: "Neyðarstjórn sveitarfélagsins", shortText: "Neyðarstjórn" },
    { id: 12, label: "Þörf fyrir viðvörun til íbúa", shortText: "Viðvörun" },
    { id: 13, label: "Þörf fyrir rýmingu", shortText: "Rýming" },
];

export const AffectableCommunityProjects: SurveySelectOption<number>[] = CommunityProjects.map(({ id, label }) => ({
    value: id,
    label,
}));
